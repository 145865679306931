import React, { useState, useMemo, useEffect } from 'react';
import {
    HvTypography,
    HvTableContainer, HvTable,
    HvTableHead, HvTableRow,
    HvTableHeader, HvTableBody,
    HvTableCell, HvPagination, useHvPagination, useHvData, HvEmptyState, hvTextColumn, useHvSortBy, useHvFilters,
    HvTag,
    HvButton,
    HvInput,
    HvGrid,
    HvDropdown,
    HvToggleButton
} from '@hitachivantara/uikit-react-core';
import { Ban, Favorite, FavoriteSelected } from '@hitachivantara/uikit-react-icons';
import _ from 'lodash';
import PropTypes from 'prop-types';
import FileUploadService from 'services/FileUploadService';
import { showBanner } from 'redux/actions/bannerActions';
import { useDispatch } from 'react-redux';
import { buildQueryParams } from 'utils/common';
import { useSelector } from 'react-redux';
import LabService from 'services/LabService';
import { resourcesType } from 'constants/common';
import { classes } from './style';
import { debounce } from 'utils/common';
import { isForwardRef } from 'react-read-more-read-less';

const Resource = (props) => {

    const [data, setData] = useState([]);
    const [resourcesCategory, setResourceCategory] = useState(props.resourcesCategory)
    const [totalResource, setTotalResource] = useState(0);
    const [resourceType, setResourceType] = useState(resourcesType);
    const [selectedresourceType, setSelectedResourceType] = useState("");
    const [filterName, setFilterName] = useState("");
    const [isFavorite, setIsFavorite] = useState(false);
    const [filteredParam, setFilteredParam] = useState(false);
    const [filterByFavorite, setFilterByFavorite] = useState(false);
    const fileUploadServiceObj = new FileUploadService();
    let urlParams = {};
    const dispatch = useDispatch();
    const user = JSON.parse(sessionStorage.getItem("HALO-USER-INFO"));
    const labServiceObj = new LabService();



    const handleFavoriteToggle = (resourceId, isFavorite) => {
        const action = isFavorite
            ? labServiceObj.deleteFavouriteResource(resourceId)
            : labServiceObj.addFavouriteResource({ user: user?.id, resource: resourceId });

        action
            .then(() => {
                setIsFavorite((prev) => {
                    return !prev; // Toggle the state
                });
            })
    };

    const getColumns = () => [
        hvTextColumn({
            id: "favorite",
            Header: "",
            accessor: "is_favorite",
            cellType: "alpha-numeric",
            style: { width: "5%" },
            Cell: (cellData) => {
                if (!_.isEmpty(cellData)) {
                    if (cellData?.cell?.row?.original?.favorite?.is_favorite) {
                        return (
                            <FavoriteSelected color={[
                                'warning']}
                                size="S"
                                title="Remove Favorite" style={{ cursor: "pointer" }}
                                onClick={() => { handleFavoriteToggle(cellData?.cell?.row?.original?.favorite?.favorite_id, cellData?.cell?.row?.original?.favorite?.is_favorite) }} />

                        )
                    } else {
                        return (
                            <Favorite color={[
                                'secondary']}
                                size="S"
                                title="Mark Favorite" style={{ cursor: "pointer" }} onClick={() => { handleFavoriteToggle(cellData?.cell?.row?.original?.id, cellData?.cell?.row?.original?.favorite?.is_favorite) }} />

                        )
                    }

                }
            }
        }),
        hvTextColumn({
            id: "name",
            Header: "Name",
            accessor: "name",
            align: "left",
            filter: "name",
            cellType: "alpha-numeric",
            Cell: (cellData) => {
                if (!_.isEmpty(cellData)) {
                    return (
                        <div style={{ display: "flex" }}>
                            <HvTypography variant='link' style={{ cursor: "pointer" }} onClick={() => { openResourceInNewTab(cellData?.cell?.row?.original?.url, cellData?.cell?.row?.original?.id) }}> {cellData?.value}</HvTypography>
                        </div>

                    );
                } else {
                    return (<></>)
                }

            },

        }),

        hvTextColumn({
            id: "resources_type",
            Header: "Type",
            accessor: "resources_type",
            cellType: "alpha-numeric",
            Cell: (cellData) => {
                if (!_.isEmpty(cellData)) {
                    return (
                        <div style={{ display: "flex" }} className={classes.tagLabel}>
                            <HvTag type="categorical" color={_.sample(["cat2", "cat3", "cat4", "cat5", "cat6"])} style={{ alignSelf: 'center', maxWidth: "100%" }} label={cellData?.cell?.row?.original?.resources_type} />
                        </div>
                    )
                }
            }
        }),


    ];

    const columns = useMemo(() => {
        return getColumns();
    }, []);

    const { getTableProps, getTableBodyProps, prepareRow, headers, page, gotoPage, state: { pageSize, pageIndex, sortBy }, getHvPaginationProps } =
        useHvData(
            {
                columns,
                data,
                defaultColumn: {
                    Cell: ({ value }) => value ?? "—",
                },
                manualPagination: true,
                manualSortBy: true,
                disableCreateExpandButton: true,
                pageCount: totalResource
            },
            useHvSortBy,
            useHvPagination
        );

    useEffect(() => {
       gotoPage(0)
    }, [sortBy, gotoPage, filteredParam]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Apply filters to the URL parameters
                if (!_.isEmpty(filterName)) {
                    urlParams["name"] = filterName;
                }
                if (!_.isEmpty(selectedresourceType)) {
                    urlParams["resources_type"] = selectedresourceType;
                }
                if (filterByFavorite) {
                    urlParams["is_favorite"] = true;
                }

                var sortByValue = "-id";

                if (sortBy?.length) {
                    sortByValue = sortBy[0].desc ? "-" + sortBy[0].id : sortBy[0].id;
                }
        
                urlParams["object_id"] = props.objectId;
                urlParams["resources_category"] = resourcesCategory;
                urlParams["limit"] = pageSize;
                urlParams["ordering"] = sortByValue;

                // Calculate offset dynamically based on conditions
                const isFiltered =
                    !_.isEmpty(filterName) ||
                    !_.isEmpty(selectedresourceType) ||
                    filterByFavorite;

                if (isFiltered && filteredParam) {
                    urlParams["offset"] = 0;
                    setFilteredParam(false);
                } else {
                    // Calculate offset for pagination based on total resource count and page size
                    const calculatedOffset = pageSize * ((pageIndex + 1) - 1);
                    urlParams["offset"] = calculatedOffset;
                    
                }

                // Fetch data
                const response = await fileUploadServiceObj.getResourceUrlByLabId(buildQueryParams(urlParams));
                if (response) {
                    const { results, count } = response;
                    if (results && results.length > 0) {
                        // Update total resource pages
                        setTotalResource(Math.ceil(count / pageSize));
                        // Map results to table data
                        const tableData = results.map((item) => {
                            let output = {};
                            if (!_.isNull(item.url)) {
                                output.type = "URL";
                                output.url = item.url;
                                output.name = item.name;
                                output.resources_type = item.resources_type;
                                output.noActions = true;
                                output.id = item?.id;
                                output.favorite = item.favorite;
                                return output;
                            }
                        });
                        setData(tableData);
                    } else {
                        setData([]);
                    }
                }
            } catch (error) {
                dispatch(showBanner({
                    payload: {
                        showBanner: true,
                        label: { message: "Error while getting resource." },
                        variant: "error",
                    },
                }));
            }
        };

        fetchData();
    }, [sortBy, pageIndex, pageSize, isFavorite, filterName, selectedresourceType, filterByFavorite]);
    const changeHandler = (value) => {
        setFilteredParam(!filteredParam);
        filterName === false ? setFilteredParam(true) : setFilteredParam(false); 
        debounceSearchLabData(value);
    }

    const debounceSearchLabData = debounce(value => {
        setFilterName(value)
    }, 1000);

    const openResourceInNewTab = (url, resourceId) => {
        var sanitizeUrl = encodeURI(url);
        const payload = {
            "resource": resourceId,
            "user": user?.id
        }
        try {
            labServiceObj.postResourceReport(payload).then((response) => { console.log(response) }).catch((error) => { console.log(error) })
        } catch (error) {
            console.log(error);
        }
        window.open(sanitizeUrl, '_blank').focus();
    };



    const EmptyRow = () => (
        <HvTableRow>
            <HvTableCell colSpan={100} style={{ height: 50 }}>
                <HvEmptyState message="No data to display" icon={<Ban role="presentation" />} />
            </HvTableCell>
        </HvTableRow>
    );


    const rowRenderer = (pages) => {
        return pages.map((row, index) => {
            prepareRow(row);
            return (
                <React.Fragment key={row.id}>
                    <HvTableRow
                        key={row.Header}
                        {...row.getRowProps({
                            "aria-rowindex": index,
                        })}
                        striped={true}
                        className={classes.tableRowColor}
                    >

                        {row.cells.map((cell) => (
                            <>
                                <HvTableCell key={cell.Header} {...cell.getCellProps()}>

                                    {cell.render("Cell")}
                                </HvTableCell>
                            </>

                        ))}
                    </HvTableRow>

                </React.Fragment>
            );
        });
    };

    const handleShowFilter = () => {
        setFilterByFavorite(!filterByFavorite)
        filterByFavorite === false ? setFilteredParam(true) : setFilteredParam(false); 
    }
    return (

        <HvTableContainer>
            <HvGrid container>
                <HvGrid item sm={6} md={4}>
                    <HvInput type='search' placeholder='Type Resource Name' onChange={(evt, val) => changeHandler(val)} />
                </HvGrid>
                <HvGrid item sm={6} md={4} style={{ display: "flex" }}>
                    <HvTypography variant='normalText' style={{ paddingRight: "10px" }}>Type</HvTypography>
                    <HvDropdown
                        id={"ResourceType"}
                        aria-label="With search"
                        multiSelect
                        onChange={(value) => {
                            const selectedResource = value.map(item => item.label).join(",");
                            setSelectedResourceType(selectedResource)
                            selectedResource === false ? setFilteredParam(true) : setFilteredParam(false); 

                        }}
                        values={resourceType}
                    />
                </HvGrid>
                <HvGrid item sm={6} md={4} style={{ display: "flex" }}>
                    <HvToggleButton
                        aria-label="Favorite"
                        notSelectedIcon={<Favorite />}
                        selectedIcon={<FavoriteSelected />}
                        title='Show Favorite' onClick={handleShowFilter}
                        className={classes.favoirteColor}
                    />
                    <HvTypography variant='normalText' style={{ paddingTop: "5px" }}> Show Favorite</HvTypography>
                </HvGrid>
            </HvGrid>

            <HvTable
                {...getTableProps({
                    "aria-rowcount": data.length,
                    caption: "Table Caption",
                })}
                style={{
                    width: "100%",
                    position: "relative",
                    paddingTop: "10px"
                }}
            >
                <HvTableHead>
                    {headers.map((col) => (
                        <HvTableHeader key={col.Header} {...col.getHeaderProps()} className={classes.headerColor}>
                            {col.render("Header")}
                        </HvTableHeader>
                    ))}
                </HvTableHead>
                <HvTableBody {...getTableBodyProps()}>
                    {page.length === 0 ? <EmptyRow /> : rowRenderer(page)}
                </HvTableBody>
            </HvTable>
            {page?.length ? (
                <HvPagination {...getHvPaginationProps()} />
            ) : undefined}
        </HvTableContainer>


    );

};

Resource.propTypes = {
    resourceData: PropTypes.any,
    totalRecords: PropTypes.any,
    objectId: PropTypes.any
}

export default Resource;