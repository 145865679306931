import { REPORT_SIZE_OPTIONS } from "constants/labs";
import {
    HvContainer,
    HvSection,
    HvDropDownMenu,
} from "@hitachivantara/uikit-react-core";
import {
    HvDatePicker,
    HvGrid,
    HvTypography,
    HvFilterGroup,
    HvPanel,
    HvEmptyState,
} from "@hitachivantara/uikit-react-core";
import { Info } from "@hitachivantara/uikit-react-icons";
// import ReportService from "services/ReportService.js";
import { useStyles } from "./styles";
import React, { useEffect, useState } from "react";
import { buildQueryParams } from "utils/common.js";
import HardwareTableView from "./HardwareTableView";
import moment from "moment";
import _ from "lodash";

function HardwareLabSchedule() {

    const [displayExport, setDisplayExport] = useState(0);
    const [pageLimit, setPageLimit] = useState(REPORT_SIZE_OPTIONS[0]);
    const [pageOffset, setPageOffset] = useState(0);
    const [selectedCategories, setSelectedCategories] = useState(["Data Resilience and Compliance", "Universal Data and Application Management", "Hybrid Cloud Solutions"]);
    const [selectedUserType, setSelectedUserType] = useState(["Employees", "Partners", "Customers"]);
    const [startDate, setStartDate] = useState(undefined);
    const [endDate, setEndDate] = useState(undefined);
    const [reportUserType, setReportUserType] = useState();
    const [customStartDate, setCustomStartDate] = useState(new Date());
    const [customEndDate, setCustomEndDate] = useState(new Date());
    const categoryArray = [
        {
            id: "Data Resilience and Compliance",
            name: "Data Resilience and Compliance",
        },
        {
            id: "Universal Data and Application Management",
            name: "Universal Data and Application Management",
        },
        {
            id: "Hybrid Cloud Solutions",
            name: "Hybrid Cloud Solutions",
        },
    ];
    const defaultCategory = [
        "Data Resilience and Compliance",
        "Universal Data and Application Management",
        "Hybrid Cloud Solutions",
    ];
    const UserType = [
        { id: "Employees", name: "Employees", value: "Employees" },
        { id: "Partners", name: "Partners", value: "Partners" },
        { id: "Customers", name: "Customers", value: "Customers" },
    ];

    const defaultUserType = ["Employees", "Partners", "Customers"]
    const [filters, setFilters] = useState([
        {
            id: "1",
            name: "Category",
            data: categoryArray,
        },
        {
            id: "2",
            name: "User Type",
            data: UserType,
        },
    ]);
    const [selectedFilterValue, setSelectedFilterValue] = useState();

    const classes = useStyles();

    // Subtract 30 days

    const labels = {
        applyLabel: "Apply",
        cancelLabel: "Cancel",
    };

    const getDataByDateRange = (values, startDate, endDate) => {

        let params = {};
        if (startDate && endDate) {
            params["start_date"] = moment(startDate).format("YYYY-MM-DD");
            params["end_date"] = moment(endDate).format("YYYY-MM-DD");
        }

        params["lab_categories"] = selectedCategories.join(",");
        params["user_group_names"] = selectedUserType.join(",");

    };

    useEffect(() => {

        getDataByDateRange([], startDate, endDate);

    }, [pageLimit, pageOffset, startDate, endDate, selectedCategories, selectedUserType]);

    return (
        <>
            <HvContainer>
                <HvPanel>
                    <HvTypography variant="xsTitle">
                        Hardware Lab bookings
                    </HvTypography>
                </HvPanel>
                <HvGrid
                    container
                    spacing={2}
                    rowSpacing={5}
                    style={{ paddingTop: "50px", paddingLeft: "25px" }}
                >
                    <HvGrid
                        item
                        xs={1}
                        sm={1}
                        md={2}
                        lg={1}
                        xl={2}
                        style={{ display: "contents", paddingLeft: "15px" }}
                    >
                        <HvTypography
                            variant="highlightText"
                            style={{ alignSelf: "center" }}
                        >
                            Select Filters:
                        </HvTypography>
                    </HvGrid>
                    <HvGrid
                        item
                        tem
                        xs={1}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        style={{ paddingTop: "5px" }}
                    >
                        <HvFilterGroup
                            aria-label="Main filter group"
                            id="reportFilter"
                            filters={filters}
                            value={selectedFilterValue}
                            onChange={(_, values) => {
                                setSelectedCategories(values[0]?.length > 0 ? values[0] : defaultCategory)
                                setSelectedUserType(values[1]?.length > 0 ? values[1] : defaultUserType)
                            }

                            }
                            onClear={() => {
                                setSelectedFilterValue([]);
                            }}
                            onCancel={() => {
                                setSelectedFilterValue([]);
                            }}
                            escapeWithReference={true}
                            height={"350px"}
                            className={classes.filterUserGroup}
                        />
                    </HvGrid>
                    <HvGrid
                        item
                        tem
                        xs={1}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                        style={{ paddingTop: "5px", position: "relative", zIndex: 1 }}
                    >
                        <HvDatePicker
                            id="DatePicker"
                            aria-label="Date"
                            placeholder="Select a range"
                            labels={labels}
                            rangeMode
                            showClear
                            // calendarProps={{
                            //     maximumDate: new Date(),
                            // }}
                            //startValue={new Date("2023-02-02T00:00:00.000Z")}
                            // startValue={new Date()}
                            // endValue={new Date()}
                            startValue={customStartDate ? customStartDate : startDate}
                            endValue={customEndDate ? customEndDate : endDate}
                            onChange={(startDate, endDate) => {
                                setStartDate(startDate);
                                setEndDate(endDate);
                            }}
                            onClear={() => {
                                // setBarChartData([]);
                                // setDounetChartData([]);
                            }}
                        />
                    </HvGrid>

                </HvGrid>
                {"s"?.length > 0 ?
                    <>
                        <HvGrid container style={{ paddingTop: "30px" }}>
                            <HvGrid
                                item
                                xs={4}
                                sm={6}
                                md={4}
                                lg={8}
                                xl={8}
                                style={{ paddingTop: "60px" }}
                            >

                            </HvGrid>
                            <HvGrid
                                item
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                style={{ paddingTop: "60px" }}
                            >

                            </HvGrid>
                        </HvGrid>
                        <HvGrid container style={{ paddingTop: "50px" }} columnGap={10}>
                            <HvGrid item xs={4} sm={8} md={8} lg={12} xl={12}>
                                <HardwareTableView
                                    startDate={moment(startDate).format("YYYY-MM-DD")}
                                    endDate={moment(endDate).format("YYYY-MM-DD")}
                                    categories={selectedCategories?.join(",")}
                                    userType={selectedUserType.join(",")}
                                />
                            </HvGrid>
                        </HvGrid>
                    </>
                    :
                    <HvEmptyState
                        icon={<Info />}
                        message="No data to display."
                    />
                }

            </HvContainer>
        </>
    );
}
export default HardwareLabSchedule;
