import React from "react";
import PropTypes from "prop-types";
import {
  HvCard,
  HvCardContent,
  HvCardHeader,
  HvActionBar,
  HvTypography,
  HvCardMedia,
  HvButton,
} from "@hitachivantara/uikit-react-core";
import { css } from "@emotion/css";
import { Next } from "@hitachivantara/uikit-react-icons";

const classes = {
  card: css({
    background: "#FBFCFC 0% 0% no-repeat padding-box",
    border: "1px solid #FBFCFC",
    borderRadius: "16px 16px 16px 16px",
    opacity: 1,
    height: "500px !important",
    width: "300px !important",
    transition: "transform 0.3s ease-in-out !important",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2) !important",
    "&:hover": {
      transform: "scale(1.05) !important",
      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2) !important",
    },
    // cursor: "pointer",
    "& .HvCard-semanticBar": {
      display: "none",
    },
    display: "flex",
    flexDirection: "column",
  }),
  media: css({
    "&.HvCardMedia-root": {
      display: "block",
      WebkitBackgroundSize: "cover",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      WebkitBackgroundPosition: "center",
      backgroundPosition: "center",
      width: "100",
      objectFit: "cover",
      width: "100%",
      borderRadius: "inherit",
    },
  }),
  actionBar: css({
    "&.HvActionBar-root": {
      justifyContent: "flex-start",
    },
  }),
};

const CategoryCard = (props) => {
  const login = (header) => {
    localStorage.setItem("Category", header);
    props.initiateLogin(header);
  };
  return (
    <div>
      <HvCard className={classes.card}>
        <HvCardMedia
          image={props?.data?.image}
          height={"30%"}
          component={"img"}
          className={classes.media}
        />
        <HvCardHeader
          title={
            <HvTypography variant="title3">{props?.data?.header}</HvTypography>
          }
        />
        <HvCardContent
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: "100%",
            width: "100%",
            paddingLeft: "35px",
          }}
        >
          <HvTypography variant="normalText">
            {props?.data?.description?.trim()}
          </HvTypography>
        </HvCardContent>
        <HvActionBar className={classes.actionBar}>
          {" "}
          <HvButton
            variant="negativeGhost"
            endIcon={<Next />}
            onClick={() => {
              login(props?.data?.header);
            }}
          >
            Explore
          </HvButton>{" "}
        </HvActionBar>
      </HvCard>
    </div>
  );
};

CategoryCard.propTypes = {
  data: PropTypes.any,
  initiateLogin: PropTypes.any,
};

export default CategoryCard;
