import { HvDonutChart } from "@hitachivantara/uikit-react-viz";
import { HvBarChart } from "@hitachivantara/uikit-react-viz";
import _ from "lodash";
import {
  HvDatePicker,
  HvGrid,
  HvTypography,
  HvPanel,
  HvDropDownMenu,
  HvEmptyState,
} from "@hitachivantara/uikit-react-core";
import { HvContainer, HvSection } from "@hitachivantara/uikit-react-core";
import { Info } from "@hitachivantara/uikit-react-icons";
import ReportService from "services/ReportService.js";
import React, { useEffect, useState } from "react";
import { buildQueryParams } from "utils/common.js";
import moment from "moment";
import TableViewResourceUtilization from "./TableViewResourceUtilization";

const reportsServiceObj = new ReportService();
function ResourceUtilization() {
  const defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate() - 30);
  const [startDate, setStartDate] = useState(defaultDate);
  const [endDate, setEndDate] = useState(new Date());
  const [selectedRadioButton, setSelectedRadioButton] = useState(0);
  const [barChartData, setBarChartData] = useState([]);
  const [dounetChartData, setDounetChartData] = useState([]);
  const [dounetChartUrlData, setDounetChartUrlData] = useState([]);

  const labels = {
    applyLabel: "Apply",
    cancelLabel: "Cancel",
  };

  function roundToTwoDecimalsAndAdjust(percentages) {
    let rounded = percentages.map((num) => Math.round(num * 100) / 100);
    let total = rounded.reduce((acc, val) => acc + val, 0);
    total = Math.round(total * 100) / 100;
    let diff = Math.round((100 - total) * 100) / 100;

    while (diff !== 0) {
      for (let i = 0; i < rounded.length; i++) {
        if (diff > 0 && rounded[i] < 100) {
          rounded[i] = Math.round((rounded[i] + 0.01) * 100) / 100;
          diff = Math.round((diff - 0.01) * 100) / 100;
        } else if (diff < 0 && rounded[i] > 0) {
          rounded[i] = Math.round((rounded[i] - 0.01) * 100) / 100;
          diff = Math.round((diff + 0.01) * 100) / 100;
        }
        if (diff === 0) break;
      }
    }

    return rounded;
  }
  //selected date range
  const getReportByCustomDateRange = async (startDate, endDate) => {
    let params = {};
    params["start_date"] = moment(startDate).format("YYYY-MM-DD");
    params["end_date"] = moment(endDate).format("YYYY-MM-DD");

    let allResults = [];
    let nextUrl = buildQueryParams(params);
    const response = await reportsServiceObj.getResourceUtilizationChartData(
      nextUrl
    );
    if (response) {
      allResults = response;
      if (allResults.length > 0) {
        const labs = {};
        const resourceCounts = {};
        const urlCounts = {};

        allResults.forEach((item) => {
          const lab = item?.lab;
          const category = item?.resources_category;
          const url = item?.url;
          const resource_name = item?.resource_name;
          if (!labs[lab]) {
            labs[lab] = {};
          }
          if (!labs[lab][category]) {
            labs[lab][category] = 0;
          }
          labs[lab][category] += 1;

          if (!resourceCounts[category]) {
            resourceCounts[category] = 0;
          }
          resourceCounts[category] += 1;

          if (!urlCounts[resource_name]) {
            urlCounts[resource_name] = {
              count: 0,
              name: resource_name,
              category: category,
            };
          }
          urlCounts[resource_name].count += 1;
        });

        const labels = Object.keys(labs);
        const categories = new Set();
        labels.forEach((lab) => {
          Object.keys(labs[lab]).forEach((category) => {
            categories.add(category);
          });
        });

        const data = {
          Group: labels,
        };

        categories.forEach((category) => {
          data[category] = labels.map((lab) => labs[lab][category] || 0);
        });

        const barReportData = [
          {
            FieldResources:
              data["Field Resources"] && data["Field Resources"].length > 0
                ? data["Field Resources"]
                : undefined,
            GlobalLearning:
              data["Global Learning"] && data["Global Learning"].length > 0
                ? data["Global Learning"]
                : undefined,
            Group: data?.Group ? data?.Group : [],
            Normal:
              data?.Normal && data?.Normal.length > 0
                ? data?.Normal
                : undefined,
          },
        ].map((obj) => {
          return Object.fromEntries(
            Object.entries(obj).filter(([key, value]) => value !== undefined)
          );
        });

        setBarChartData(barReportData);

        const donutChartData = Object.keys(resourceCounts).map((key) => ({
          label: key,
          value: resourceCounts[key],
        }));
        setDounetChartData(donutChartData);

        // Get top 10 resources based on count
        const top10Resources = Object.entries(urlCounts)
          .sort((a, b) => b[1].count - a[1].count)
          .slice(0, 10)
          .map(([url, { count, name, category }]) => ({
            url,
            count,
            name,
            combinedName: `${name} (${category})`, // Combine name and category
          }));

        setDounetChartUrlData(top10Resources);
      } else {
        setBarChartData([]);
        setDounetChartData([]);
        setDounetChartUrlData([]);
      }
    }
  };

  // Export data to Excel
  const getExcelLabReport = () => {
    let tableParams = {};
    tableParams["start_date"] = moment(startDate).format("YYYY-MM-DD");
    tableParams["end_date"] = moment(endDate).format("YYYY-MM-DD");
    tableParams["file_format"] = "excel";
    reportsServiceObj
      .getResourcePDFExcelReport(buildQueryParams(tableParams))
      .then((response) => {
        // Check if the response is a Blob
        if (response instanceof Blob) {
          const url = window.URL.createObjectURL(response);

          const link = document.createElement("a");
          link.href = url;
          link.download = "lab_report.xlsx"; // Set the desired filename

          document.body.appendChild(link);
          link.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        } else {
          console.error("Unexpected response type:", response);
        }
      })
      .catch((error) => {
        console.error("Error fetching Excel data:", error);
      });
  };
  //export data to PDF
  const getPDFLabReport = () => {
    let tableParams = {};
    tableParams["start_date"] = moment(startDate).format("YYYY-MM-DD");
    tableParams["end_date"] = moment(endDate).format("YYYY-MM-DD");
    tableParams["file_format"] = "pdf";
    reportsServiceObj
      .getResourcePDFExcelReport(buildQueryParams(tableParams))
      .then((response) => {
        // Create a URL for the Blob
        const url = window.URL.createObjectURL(response);

        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = "lab_report.pdf"; // Set the desired filename

        // Append the link to the DOM and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the URL
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching PDF data:", error);
      });
  };

  useEffect(() => {
    getReportByCustomDateRange(startDate, endDate);
  }, []);

  return (
    <>
      <HvContainer>
        <HvPanel>
          <HvTypography variant="xsTitle">
            Labs Details Report - Resource Utilization
          </HvTypography>
        </HvPanel>
        <HvGrid
          container
          spacing={2}
          rowSpacing={5}
          style={{ paddingTop: "50px", paddingLeft: "25px" }}
        >
          <HvGrid
            item
            xs={1}
            sm={1}
            md={2}
            lg={1}
            xl={2}
            style={{ display: "contents", paddingLeft: "15px" }}
          >
            <HvTypography
              variant="highlightText"
              style={{ alignSelf: "center" }}
            >
              Select Dates:
            </HvTypography>
          </HvGrid>

          <HvGrid
            item
            tem
            xs={2}
            sm={2}
            md={4}
            lg={2}
            xl={2}
            style={{ paddingTop: "5px", paddingRight: "10px" }}
          >
            <HvDatePicker
              id="DatePicker"
              aria-label="Date"
              placeholder="Select a range"
              labels={labels}
              showClear
              rangeMode
              calendarProps={{
                maximumDate: new Date(),
              }}
              startValue={startDate}
              endValue={endDate}
              onChange={(startDate, endDate) => {
                setStartDate(startDate);
                setEndDate(endDate);
                setBarChartData([]);
                getReportByCustomDateRange(startDate, endDate);
              }}
              onClear={() => {
                setBarChartData([]);
                setDounetChartData([]);
              }}
            />
          </HvGrid>

          {barChartData?.length > 0 ? (
            <HvGrid
              item
              xs={1}
              sm={1}
              md={2}
              lg={1}
              xl={2}
              style={{ position: "absolute", top: 125, right: 100 }}
            >
              {selectedRadioButton === 1 ? (
                <>
                  <HvDropDownMenu
                    dataList={[
                      {
                        label: "Download Excel Report",
                      },
                      {
                        label: "Download PDF Report",
                      },
                    ]}
                    //defaultExpanded
                    placement="left"
                    size="md"
                    variant="secondaryGhost"
                    onClick={(e, value) =>
                      value.label === "Download Excel Report"
                        ? getExcelLabReport()
                        : getPDFLabReport()
                    }
                  />
                </>
              ) : (
                <>
                  <HvDropDownMenu
                    dataList={[
                      {
                        label: "Download Excel Report",
                      },
                      {
                        label: "Download PDF Report",
                      },
                    ]}
                    //defaultExpanded
                    placement="left"
                    size="md"
                    variant="secondaryGhost"
                    onClick={(e, value) =>
                      value.label === "Download Excel Report"
                        ? getExcelLabReport()
                        : getPDFLabReport()
                    }
                  />
                </>
              )}
            </HvGrid>
          ) : (
            ""
          )}
        </HvGrid>

        <HvGrid container style={{ paddingTop: "30px" }}>
          {barChartData?.length > 0 ? (
            <>
              <HvGrid
                item
                xs={4}
                sm={6}
                md={4}
                lg={6}
                xl={6}
                style={{ paddingTop: "60px" }}
              >
                <HvSection
                  key={"bar-0"}
                  title={
                    <HvTypography variant="label">
                      Resource Usage By Lab
                    </HvTypography>
                  }
                >
                  <HvBarChart
                    key={"bar-0"}
                    data={barChartData[0]}
                    groupBy="Group"
                    measures={Object.keys(barChartData[0]).filter(
                      (key) => key !== "Group"
                    )}
                    stack="total"
                    horizontal
                    title="Lab Usage By Region"
                    height={400}
                    yAxis={{
                      type: "categorical",
                      position: "left",
                      label: {
                        formatter: function (value) {
                          return value.split(" ").join("\n");
                        },
                      },
                    }}
                    grid={{ left: 100 }}
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      whiteSpace: "normal",
                    }}
                    onOptionChange={(option) => {
                      if (
                        Array.isArray(option.yAxis) &&
                        option.yAxis.length === 1
                      ) {
                        option.yAxis = [
                          {
                            ...option.yAxis[0],
                            axisLabel: {
                              ...option.yAxis[0].axisLabel,
                              width: 90,
                              overflow: "truncate",
                            },
                          },
                          (option.legend.show = true),
                        ];
                      }
                      option.grid = [
                        {
                          left: "3%",
                          right: "4%",
                          bottom: barChartData[0]?.count > 10 ? "2%" : "3%", // Increase bottom margin if slider is present
                          containLabel: true,
                        },
                      ];
                      option.dataZoom =
                        barChartData[0]?.count > 10
                          ? [
                              {
                                type: "slider",
                                yAxisIndex: 0,
                                start: 0,
                                end: barChartData[0]?.count > 10 ? 10 : 100, // Show the first 10 items initially
                                labelFormatter: "", // Set to empty to remove the labels
                              },
                            ]
                          : [];

                      option.graphic =
                        barChartData[0]?.count === 0
                          ? {
                              type: "text",
                              left: "left",
                              top: "middle",
                              style: {
                                text: "No data",
                                fontSize: 30,
                                fill: "#aaa",
                              },
                            }
                          : {};
                      option.tooltip = {
                        confine: true,
                      };
                      return option;
                    }}
                  />
                </HvSection>
              </HvGrid>
            </>
          ) : (
            <></>
          )}
          {barChartData?.length > 0 ? (
            <>
              <HvGrid
                item
                xs={4}
                sm={4}
                md={4}
                lg={6}
                xl={6}
                style={{ paddingTop: "60px" }}
              >
                <HvSection
                  key={"donut-0"}
                  classes={{
                    content: "css-10klw3m",
                  }}
                  title={
                    <HvTypography variant="label">
                      Aggregate Lab Resource Usage (%)
                    </HvTypography>
                  }
                >
                  <HvDonutChart
                    key={"donut-0"}
                    data={{
                      count: dounetChartData.map((item) => item.value),
                      resourceType: dounetChartData.map((item) => item.label),
                    }}
                    groupBy="resourceType"
                    measure="count"
                    type="regular"
                    title="Aggregate Lab Resource Usage"
                    height={400}
                    width={700}
                    legend={{
                      direction: "vertical",
                      position: {
                        x: "left",
                        y: "top",
                      },
                    }}
                    onOptionChange={(option) => {
                      option.series = [
                        {
                          type: "pie",
                          radius: ["50%", "70%"],
                          avoidLabelOverlap: false,
                          label: {
                            show: false,
                            position: "center",
                          },
                          itemStyle: {
                            color: function (params) {
                              const category =
                                dounetChartData[params.dataIndex].label;
                              if (category === "Global Learning") {
                                return "#91CC75"; // Green
                              } else if (category === "Normal") {
                                return "#FAC858"; // Yellow
                              } else if (category === "Field Resources") {
                                return "#5470C6"; // Blue
                              }
                              return "#9A60B4"; // Default color
                            },
                          },
                        },
                      ];
                      option.legend = {
                        orient: "vertical",
                        type: "scroll",
                        left: "left", // Position the legend to the left
                        top: "middle",
                      };
                      option.tooltip = {
                        confine: true,
                        formatter: function (params) {
                          const resource = params.name;
                          const count = params.value;
                          return `${resource} : ${count}`;
                        },
                      };
                      return option;
                    }}
                  />
                </HvSection>
              </HvGrid>
            </>
          ) : (
            <></>
          )}

          {barChartData?.length > 0 ? (
            <>
              <HvGrid
                item
                xs={4}
                sm={4}
                md={4}
                lg={6}
                xl={6}
                style={{ paddingTop: "60px" }}
              >
                <HvSection
                  key={"bar-1"}
                  classes={{
                    content: "css-10klw3m",
                  }}
                  title={
                    <HvTypography variant="label">
                      Top 10 Resources Used
                    </HvTypography>
                  }
                >
                  <HvBarChart
                    key={"bar-1"}
                    data={{
                      Resource: dounetChartUrlData.map((item) => item.name),
                      Count: dounetChartUrlData.map((item) => item.count),
                    }}
                    groupBy="Resource"
                    measures="Count"
                    horizontal
                    height={400}
                    yAxis={{
                      type: "categorical",
                      position: "left",
                      label: {
                        formatter: function (value) {
                          return value.split(" ").join("\n");
                        },
                      },
                    }}
                    grid={{ left: 100 }}
                    style={{
                      width: "100%",
                      overflow: "hidden",
                      whiteSpace: "normal",
                    }}
                    onOptionChange={(option) => {
                      if (
                        Array.isArray(option.yAxis) &&
                        option.yAxis.length === 1
                      ) {
                        option.yAxis = [
                          {
                            ...option.yAxis[0],
                            axisLabel: {
                              ...option.yAxis[0].axisLabel,
                              width: 90,
                              overflow: "truncate",
                            },
                          },
                        ];
                      }
                      option.grid = [
                        {
                          left: "3%",
                          right: "4%",
                          bottom:
                            dounetChartUrlData[0]?.count > 10 ? "2%" : "3%", // Increase bottom margin if slider is present
                          containLabel: true,
                        },
                      ];
                      option.dataZoom =
                        dounetChartUrlData[0]?.count > 10
                          ? [
                              {
                                type: "slider",
                                yAxisIndex: 0,
                                start: 0,
                                end:
                                  dounetChartUrlData[0]?.count > 10 ? 10 : 100, // Show the first 10 items initially
                                labelFormatter: "", // Set to empty to remove the labels
                              },
                            ]
                          : [];
                      option.graphic =
                        dounetChartUrlData[0]?.count === 0
                          ? {
                              type: "text",
                              left: "left",
                              top: "middle",
                              style: {
                                text: "No data",
                                fontSize: 30,
                                fill: "#aaa",
                              },
                            }
                          : {};
                      option.series = [
                        {
                          type: "bar",
                          itemStyle: {
                            color: function (params) {
                              const category =
                                dounetChartUrlData[params.dataIndex]
                                  .combinedName;
                              if (category.includes("Global Learning")) {
                                return "#91CC75"; // Green
                              } else if (category.includes("Normal")) {
                                return "#FAC858"; // Yellow
                              } else if (category.includes("Field Resources")) {
                                return "#5470C6"; // Blue
                              }
                              return "#9A60B4"; // Default color
                            },
                          },
                        },
                      ];
                      option.tooltip = {
                        confine: true,
                      };
                      return option;
                    }}
                  />
                </HvSection>
              </HvGrid>
            </>
          ) : (
            <></>
          )}

          {barChartData?.length > 0 ? (
            <>
              <HvGrid
                item
                xs={4}
                sm={4}
                md={4}
                lg={6}
                xl={6}
                style={{ paddingTop: "60px" }}
              >
                <HvSection
                  key={"donut-1"}
                  classes={{
                    content: "css-10klw3m",
                  }}
                  title={
                    <HvTypography variant="label">
                      Top 10 Resources Used
                    </HvTypography>
                  }
                >
                  <HvDonutChart
                    key={"donut-1"}
                    data={{
                      count: dounetChartUrlData.map((item) => item.count),
                      resource: dounetChartUrlData.map(
                        (item) => item.combinedName
                      ),
                    }}
                    groupBy="resource"
                    measure="count"
                    type="regular"
                    title="Top 10 Resources Used"
                    height={400}
                    width={700}
                    legend={{
                      direction: "vertical",
                      position: {
                        x: "left",
                        y: "top",
                      },
                    }}
                    onOptionChange={(option) => {
                      option.series = [
                        {
                          type: "pie",
                          radius: ["50%", "70%"],
                          avoidLabelOverlap: false,
                          label: {
                            show: false,
                            position: "center",
                          },
                        },
                      ];
                      option.legend = {
                        orient: "vertical",
                        type: "scroll",
                        left: "left", // Position the legend to the left
                        top: "middle", // Center the legend vertically
                        formatter: function (name) {
                          const maxLength = 15; // Set the maximum length for legend names
                          if (name.length > maxLength) {
                            return name.substring(0, maxLength) + "...";
                          }
                          return name;
                        },
                      };
                      option.tooltip = {
                        confine: true,
                        formatter: function (params) {
                          const combinedName = params.name;
                          const percentage = params.percent;
                          const count = params.value[1];
                          return `${combinedName} : ${percentage}% (${count})`;
                        },
                      };
                      option.tooltip = {
                        confine: true,
                      };
                      return option;
                    }}
                  />
                </HvSection>
              </HvGrid>
            </>
          ) : (
            <></>
          )}
        </HvGrid>
        <HvGrid container style={{ paddingTop: "50px" }} columnGap={10}>
          {barChartData?.length > 0 ? (
            <>
              <HvGrid item xs={4} sm={8} md={8} lg={12} xl={12}>
                <TableViewResourceUtilization
                  startDate={moment(startDate).format("YYYY-MM-DD")}
                  endDate={moment(endDate).format("YYYY-MM-DD")}
                />
              </HvGrid>
            </>
          ) : (
            <></>
          )}
        </HvGrid>
        {barChartData?.length === 0 ? (
          <HvEmptyState icon={<Info />} message="No data to display." />
        ) : (
          <></>
        )}
      </HvContainer>
    </>
  );
}

export default ResourceUtilization;
