import { css } from "@emotion/css";

export const classes = {
  globalAction: css({
    "& .HvGlobalActions-wrapper": {
      justifyContent: "center",
    },
  }),
  readOrHide: css({
    color: "#1874CD",
    cursor: "pointer",
    textDecoration: "underline",
  }),
  closeButton: css({
    display: "none",
  }),
};

