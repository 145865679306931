import React, { useEffect, useRef, useState } from "react";
import { classes } from "./style";
import LabsCardComp from "./LabsCardComp";
import ScPortal from "components/newScPortal/ScPortal";
import {
  HvButton,
  HvDropdown,
  HvPagination,
  HvTypography,
  HvBox,
  HvToggleButton
} from "@hitachivantara/uikit-react-core";
import LabService from "services/LabService";
import { PAGINATION_SIZE_OPTIONS } from "constants/labs";
import LabCategoryService from "services/admin/masters/LabCategoryService";
import { buildQueryParams } from "utils/common";
import { LOGGED_IN_USER_INFO, LAB_ERROR_MESSAGE } from "constants/common";
import Unauthorized from "components/Unauthorized";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { currentPage, currentPageSize, showFullScreen } from "redux/actions/commonActions";
import _ from "lodash";
import { Speedometer, Calendar } from "@hitachivantara/uikit-react-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { showBanner } from 'redux/actions/bannerActions';
import AgreementDialog from "components/agreementDialog/AgreementDialog";
import { Favorite, FavoriteSelected } from "@hitachivantara/uikit-react-icons";


const labServiceObj = new LabService();
const labCategoryServiceObj = new LabCategoryService();

const LabLandingPage = ({ history }) => {
  const inputRef = useRef("");
  const { location } = history;
  const searchedString = location && location.state && location.state.searchedString;
  let previousPageSizeNumber = useSelector((state) => state.commonReducer.currentPageSize);
  const [allLabs, setAllLabsData] = useState([]);
  const [runningLabs, setAllRunningAllLabs] = useState();
  const [scheduledLabs, setAllScheduledLabs] = useState();
  let [runningLab, setRunningLab] = useState();
  const [showSCPortal, setShowSCPortal] = useState(false);
  let [allLabsCount, setAllLabsCount] = useState(0);
  let [runningLabsCount, setRunningLabsCount] = useState(0)
  let [scheduledLabsCount, setScheduledLabsCount] = useState(0)
  let [showAllLabs, setShowAllLabs] = useState(true)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(previousPageSizeNumber > 0 ? previousPageSizeNumber : PAGINATION_SIZE_OPTIONS[1]);
  const [labCategories, setLabCategories] = useState([]);
  const [labCategoryName, setLabCategoryName] = useState("All");
  const [searchOffSet, setSearchOffSet] = useState(false);
  const [errorMessage, setErroMessage] = useState(LAB_ERROR_MESSAGE);
  const [openAgreementDialog, setOpenAgreementDialog] = useState(false);
  const [acceptedAggreement, setAcceptedAgreement] = useState(false)
  const screenSize = window.screen.width
  let data = sessionStorage.getItem(LOGGED_IN_USER_INFO);
  let token = data ? JSON.parse(data) : {}
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();
  const hasCustomer = token?.groups?.map(item => item.name === "Customers");
  const isCustomer = hasCustomer?.includes(true);
  const [previousPage, setPreviousPage] = useState(useSelector((state) => state.commonReducer.currentPage));
  const [previousPageSize, setPreviousPageSize] = useState(previousPageSizeNumber);
  const [favouriteFilter, setFavoriteFilter] = useState(false);
 
  useEffect(() => {
    if ((isAuthenticated || token?.access_token)) {
      if (isCustomer && acceptedAggreement === false) {
        setOpenAgreementDialog(true);
      } else {
        if (allLabsCount === 0 || (!_.isNull(searchedString) && !_.isUndefined(searchedString)) || selectedTabIndex === 0) {
          setErroMessage(LAB_ERROR_MESSAGE);
          getInitialAllLabsData();
        }
        if (!_.isEmpty(localStorage.getItem("Category"))) {
          setLabCategoryName(localStorage.getItem("Category"))
        }
      }

    } else {
      history.push("/")
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labCategoryName, page, pageSize, inputRef.current, searchedString, isAuthenticated, acceptedAggreement, previousPage, previousPageSize, favouriteFilter]);

  useEffect(() => {
    setPage(0);
    setLabCategoryName('All');
    if ((isAuthenticated) || !_.isEmpty(token.access_token)) {
      if (selectedTabIndex === 0) {
        let labCategories = !_.isEmpty(localStorage.getItem("Category")) ? [{ label: "All", id: "all", selected: false }] : [{ label: "All", id: "all", selected: true }];
        const params = { is_deleted: false };
        labCategoryServiceObj
          .getAll(buildQueryParams(params))
          .then((response) => {
            if (response) {
              const results = response;
              results &&
                results.length > 0 &&
                results.forEach((el) => {
                  if (localStorage.getItem("Category") === el) {
                    labCategories.push({
                      label: el,
                      id: el,
                      selected: true
                    });
                  } else {
                    labCategories.push({
                      label: el,
                      id: el,
                      selected: false
                    });
                  }

                });
              setLabCategories(labCategories);
              if (!_.isEmpty(localStorage.getItem("Category"))) {
                setLabCategoryName(localStorage.getItem("Category"))
              }
            }
          })
          .catch(() => {
            console.log("error in fetching lab categories");
          });
      }
    }

  }, [selectedTabIndex, isAuthenticated, token.access_token]);


  const getAllLabsData = () => {
    let urlParams = {};
    let searchBarValue = !_.isEmpty(searchedString) ? searchedString : allLabsCount === 0 ? "" : inputRef.current?.value
    urlParams["limit"] = pageSize;
    urlParams["offset"] = pageSize * ((page + 1) - 1);
    urlParams["is_deleted"] = false;
    urlParams["status"] = 'Active';
    if (favouriteFilter) {
      urlParams["is_favorite"] = true;
    }
    if (searchBarValue) urlParams["name"] = searchBarValue;
    if (labCategoryName !== "All" || !_.isEmpty(localStorage.getItem("Category"))) {
      urlParams["category"] = !_.isEmpty(localStorage.getItem("Category")) ? localStorage.getItem("Category") : labCategoryName;

    }
    labServiceObj.allLabUrl(buildQueryParams(urlParams)).then((response) => {
      if (response.results.length > 0) {
        setAllLabsData(response.results);
        setAllLabsCount(response.count);
        setSelectedTabIndex(0)
        setShowAllLabs(true)
        if (showSCPortal) {
          setShowSCPortal(false);
        }
      } else {
        setErroMessage("No Labs Found.")
      }


    });
  }
  const formScheduledLabData = (result) => {
    let labObj = [];
    result.results.map(lab => {
      labObj.push({ ...lab.lab, ...{ 'schedule_from': lab.schedule_from, 'schedule_to': lab.schedule_to, 'slotID': lab.id } })
    });
    return labObj
  }

  const getInitialAllLabsData = () => {
    let urlParams = {};
    const currentPage = !_.isNull(previousPage) && previousPage >= 0 ? previousPage : page;
    const currentPageSize = !_.isNull(previousPageSize) && previousPageSize > 0 ? previousPageSize : pageSize
    let searchBarValue = !_.isEmpty(searchedString) ? searchedString : allLabsCount === 0 ? "" : inputRef.current?.value
    urlParams["limit"] = currentPageSize;
    urlParams["offset"] = (!_.isEmpty(searchBarValue) || labCategoryName !== "All" || favouriteFilter) && allLabsCount < currentPageSize ? 0 : currentPageSize * ((currentPage + 1) - 1);
    urlParams["is_deleted"] = false;
    urlParams["status"] = 'Active';

    if (favouriteFilter) {
      urlParams["is_favorite"] = true;
    }
    if (searchBarValue) urlParams["name"] = searchBarValue;
    if (labCategoryName !== "All" || !_.isEmpty(localStorage.getItem("Category"))) {
      urlParams["categories"] = !_.isEmpty(localStorage.getItem("Category")) ? localStorage.getItem("Category") : labCategoryName;

    }

    labServiceObj.allLabUrl(buildQueryParams(urlParams)).then((response) => {
      if (response.results.length > 0) {
        setAllLabsData(response.results);
        setSearchOffSet(false);
        setSelectedTabIndex(0)
        setAllLabsCount(response.count);
        setShowAllLabs(true)
        if (showSCPortal) {
          setShowSCPortal(false);
        }
      } else {
        setAllLabsCount(0);
        setErroMessage("No Labs found.")
      }

      if (token?.access_token || isAuthenticated) {
        let urlParams = {};
        urlParams["is_deleted"] = false
        labServiceObj.runningLab(buildQueryParams(urlParams)).then((runningLabResponse) => {
          if (runningLabResponse?.results.length > 0) {
            setAllRunningAllLabs(runningLabResponse.results);
            runningLabResponse.results.length > 0 && (labCategoryName === "All" || labCategoryName !== "All") ? setSelectedTabIndex(0) : setSelectedTabIndex(1);
            setRunningLabsCount(runningLabResponse.results.length);
            setShowSCPortal(false);
            setShowAllLabs(false);
            setAllLabsCount(response.count);
          }
        })

        labServiceObj.scheduledLab(buildQueryParams(urlParams)).then((scheduledLabResponse) => {
          if (scheduledLabResponse?.results.length > 0) {
            const labsData = formScheduledLabData(scheduledLabResponse)
            setAllScheduledLabs(labsData);
            scheduledLabResponse.results.length > 0 && (labCategoryName === "All" || labCategoryName !== "All") ? setSelectedTabIndex(0) : setSelectedTabIndex(2);
            setScheduledLabsCount(labsData.length);
            setShowSCPortal(false);
            setShowAllLabs(false);
            setAllLabsCount(response.count);
          }
        })


      }

    }).catch(() => {
      dispatch(showBanner({
        payload: {
          showBanner: true,
          label: { message: "Error while fetching labs. Looks like your session got expired or you are not authorized to access the labs. Please contact halo.support@hitachivantara.com" },
          variant: "error"
        }
      }))
    });

  };

  const getRunningLabData = () => {
    if (token?.access_token) {
      let urlParams = {};
      urlParams["is_deleted"] = false
      setSelectedTabIndex(1);
      setLabCategoryName("All");
      setLabCategories(labCategories.map((item) => {
        if (item.label === "All") {
          return { ...item, selected: true }
        } else {
          return { ...item, selected: false }
        }
      }))
      labServiceObj.runningLab(buildQueryParams(urlParams)).then((response) => {
        if (response?.results.length > 0) {
          setAllRunningAllLabs(response.results);
          setRunningLabsCount(response.results.length);
          setShowSCPortal(false)
          setShowAllLabs(false)
        }

      });
    }

  };
  const getScheduledLabData = () => {
    if (token?.access_token) {
      let urlParams = {};
      urlParams["is_deleted"] = false
      setLabCategories(labCategories.map((item) => {
        if (item.label === "All") {
          return { ...item, selected: true }
        } else {
          return { ...item, selected: false }
        }
      }))
      labServiceObj.scheduledLab(buildQueryParams(urlParams)).then((responseSchedule) => {
        if (responseSchedule?.results.length > 0) {
          const labsData = formScheduledLabData(responseSchedule)
          setAllScheduledLabs(labsData);
          setScheduledLabsCount(labsData.length);
          setSelectedTabIndex(2);
          setShowSCPortal(false)
          setShowAllLabs(false)
        }
        else {
          setSelectedTabIndex(0);
          setScheduledLabsCount(0);

        }

      });
    }


  };


  const setAllLabs = (allLabs, labData) => {
    setShowAllLabs(allLabs)
    setAllLabsData(labData)
    setRunningLabsCount(labData?.length)
    setRunningLab(labData[0])
    setShowSCPortal(!showSCPortal);
  }
  let numPages = Math.ceil(allLabsCount / pageSize);

  const handleScportalTerminate = () => {
    setShowSCPortal(!showSCPortal);
    setRunningLabsCount(0);
    setAllLabsCount(0);
    setSelectedTabIndex(0);
    if (inputRef.current?.value !== "") {
      inputRef.current.value = "";
    }
    dispatch(showFullScreen(false));

  }

  const handleScPortalDisconnect = () => {
    setShowSCPortal(!showSCPortal);
    setShowAllLabs(false);
    getRunningLabData();
    if (inputRef.current?.value !== "") {
      inputRef.current.value = "";
    }
    dispatch(showFullScreen(false));

  }
  const setPageValue = (value) => {
    dispatch(currentPage(value));
    setPreviousPage(value);
    setPage(value);
  }
  const markFavorite = () => {
    switch (selectedTabIndex) {
      case 0:
          getInitialAllLabsData();
        break;
      case 1 :
         getRunningLabData();
      case 2 :
        getScheduledLabData();
      default:
        break;
    }
  }
  const returnLabsCard = (selectedTabIndex) => {
    switch (selectedTabIndex) {
      case 0:
        return (
          allLabsCount > 0 &&
          <LabsCardComp labsData={allLabs} selectedTabIndex={selectedTabIndex} runningLab={runningLabsCount} showAllLabs={showAllLabs} setAllLabs={setAllLabs} showSCPortal={showSCPortal} markFavorite={markFavorite} />
        )
      case 1:
        return (<LabsCardComp labsData={runningLabs} selectedTabIndex={selectedTabIndex} runningLab={runningLabsCount} showAllLabs={showAllLabs} setAllLabs={setAllLabs} showSCPortal={showSCPortal} markFavorite={markFavorite} />
        )
      case 2: return (
        <LabsCardComp labsData={scheduledLabs} selectedTabIndex={selectedTabIndex} runningLab={runningLabsCount} showAllLabs={showAllLabs} setAllLabs={setAllLabs} showSCPortal={showSCPortal} markFavorite={markFavorite} getScheduledLabData={getScheduledLabData} />
      )

    }
  }

  return (
    <>
      {
        screenSize >= 768 ?
          <div style={showSCPortal ? { padding: "0px" } : { paddingTop: "30px", paddingLeft: "30px" }}>
            <div style={showSCPortal ? { display: "none", justifyContent: "space-between" } : { display: "flex", justifyContent: "space-between" }}>
              <HvBox style={{ marginBottom: "30px", display: "flex" }} >
                <HvButton
                  variant="secondaryGhost"
                  className={allLabs && selectedTabIndex === 0 ? classes.activeButton : classes.button}
                  onClick={() => getAllLabsData()}>
                  <Speedometer color={"black"} />
                  <HvTypography>All Labs {allLabsCount ? `(${allLabsCount})` : null} </HvTypography>
                </HvButton>
                {
                  runningLabsCount > 0 ? <HvButton
                    variant="secondaryGhost"
                    disabled={runningLabsCount > 0 ? false : true}
                    onClick={() => getRunningLabData()}
                    style={selectedTabIndex === 1 ? { background: '#d4dad9' } : { background: "#FBFCFC" }}>
                    <Speedometer />
                    <HvTypography>Running Labs({runningLabsCount}/{allLabsCount})</HvTypography>
                  </HvButton> : <></>
                }
                {
                  scheduledLabsCount > 0 ? <HvButton
                    variant="secondaryGhost"
                    disabled={scheduledLabsCount > 0 ? false : true}
                    onClick={() => getScheduledLabData()}
                    style={selectedTabIndex === 2 ? { background: '#d4dad9' } : { background: "#FBFCFC" }}>
                    <Calendar />
                    <HvTypography>Scheduled Labs({scheduledLabsCount})</HvTypography>
                  </HvButton> : <></>
                }
              </HvBox>
              <HvBox className={classes.dropdown} style={{ margin: 'inherit', justifyContent: "end", display: "flex", gap: "2px" }}>
                <HvToggleButton
                  aria-label="Favorite"
                  notSelectedIcon={favouriteFilter ? <FavoriteSelected /> : <Favorite />}
                  selectedIcon={favouriteFilter ? <FavoriteSelected /> : <Favorite />}
                  title='Show Favorite'
                  onClick={() => { setFavoriteFilter(!favouriteFilter); setAllLabsCount(0); }}
                  className={classes.favoirteColor}
                />
                <HvTypography variant='normalText' style={{ paddingTop: "5px", paddingRight: "5px" }}> Show Favorite</HvTypography>
                <HvDropdown
                  id="dropdown7"
                  aria-label="Single selection"
                  placement="right"
                  maxheight={350}
                  style={{ width: '350px' }}
                  hasTooltips
                  onChange={(item) => {
                    setLabCategoryName(item?.label)
                    setSearchOffSet(true);
                    setAllLabsCount(0);
                    localStorage.removeItem("Category")
                  }
                  }
                  values={labCategories}
                />
              </HvBox>

            </div>

            {
              showSCPortal ? <ScPortal data={runningLab} handleScportalTerminate={handleScportalTerminate} handleScPortalDisconnect={handleScPortalDisconnect} showSCPortal={showSCPortal} /> : <></>
            }

            {returnLabsCard(selectedTabIndex)}

            {
              allLabsCount === 0 ?
                <HvBox>
                  <HvTypography style={{ paddingTop: 27, textAlign: 'center' }} variant="selectedNavText">{errorMessage}</HvTypography>
                </HvBox> : <></>
            }

            {
              selectedTabIndex === 0 && allLabsCount > 0 ?
                <HvPagination
                  id="pagination"
                  pages={numPages}
                  page={previousPage ? previousPage : page}
                  canPrevious={page > 0 || previousPage > 0}
                  canNext={page < numPages - 1 || previousPage < numPages - 1}
                  pageSize={previousPageSize ? previousPageSize : pageSize}
                  pageSizeOptions={PAGINATION_SIZE_OPTIONS}
                  onPageChange={setPageValue}
                  onPageSizeChange={(value) => {
                    dispatch(currentPageSize(value));
                    setPageSize(value);
                    setPreviousPageSize(value);
                    setAllLabsCount(0);
                  }}
                  labels={{ pageSizeEntryName: "/ page" }}
                  style={{ padding: "30px" }}

                /> : <></>

            }
            {
              openAgreementDialog ?
                <AgreementDialog openAgreementDialog={(value) => { setOpenAgreementDialog(value) }} launchOrScheduleLab={(value) => {
                  setAcceptedAgreement(value);
                }} />
                : <></>
            }

          </div> : <Unauthorized />
      }
    </>

  );
};

export default LabLandingPage;