import React from "react";
import PropTypes from "prop-types";
import {
  HvCard,
  HvCardContent,
  HvCardHeader,
  HvActionBar,
  HvTypography,
} from "@hitachivantara/uikit-react-core";
import { css } from "@emotion/css";

const classes = {
  card: css({
    background: "#FBFCFC 0% 0% no-repeat padding-box",
    border: "1px solid #FBFCFC",
    borderRadius: "16px 16px 16px 16px",
    opacity: 1,
    height: "100% !important",
    width: "100% !important",
    transition: "transform 0.3s ease-in-out !important",
    boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2) !important",
    "&:hover": {
      transform: "scale(1.05) !important",
      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.2) !important",
    },
    // cursor:"pointer",
    "& .HvCard-semanticBar": {
      display: "none",
    },
    display: "flex",
    flexDirection: "column",
  }),
  actionBar: css({
    "&.HvActionBar-root": {
      padding: "8px",
      background:
        "linear-gradient(90deg, #CC0100 0%, #BD1DC7 75.85%, #7919AB 100%)",
      borderRadius: "16px 16px 16px 16px",
    },
  }),
};

const HomePageCard = (props) => {
  const csvDescription = (items) => {
    return (
      <ul>
        {items.map((item, index) => (
          <>
            <li key={index} style={{ marginBottom: "10px" }}>
              <HvTypography variant="title4">{item.trim()}</HvTypography>
            </li>
          </> // Trim to remove any extra spaces
        ))}
      </ul>
    );
  };
  return (
    <HvCard className={classes.card}>
      <HvCardHeader
        title={
          <HvTypography variant="title3">{props?.data?.header}</HvTypography>
        }
      />
      <HvCardContent
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          height: "100%",
          paddingLeft: "35px",
        }}
      >
        {csvDescription(props?.data?.description)}
      </HvCardContent>
      <HvActionBar className={classes.actionBar} />
    </HvCard>
  );
};

HomePageCard.propTypes = {
  data: PropTypes.any,
};

export default HomePageCard;
