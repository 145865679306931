import React, { useState, useMemo, useEffect } from "react";
import {
  HvTableContainer,
  HvTable,
  HvTableHead,
  HvTableRow,
  HvTableHeader,
  HvTableBody,
  HvTableCell,
  HvPagination,
  useHvPagination,
  useHvData,
  HvEmptyState,
  hvTextColumn,
  useHvSortBy,
  hvNumberColumn,
  hvDateColumn,
} from "@hitachivantara/uikit-react-core";
import { Ban } from "@hitachivantara/uikit-react-icons";
import _ from "lodash";
import PropTypes from "prop-types";
import ReportService from "services/ReportService.js";
import { buildQueryParams } from "utils/common.js";

const TableViewResourceUtilization = (props) => {
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState();
  const [startDate] = useState(props?.startDate);
  const [endDate] = useState(props.endDate);
  const reportsServiceObj = new ReportService();

  const getColumns = () => [
    hvNumberColumn({
      Header: "ID",
      accessor: "id",
      cellType: "alpha-numeric",
    }),
    hvTextColumn({
      Header: "Lab Name",
      accessor: "lab",
      cellType: "alpha-numeric",
    }),
    hvTextColumn({
      Header: "Resource Name",
      accessor: "resource_name",
      cellType: "alpha-numeric",
    }),
    hvTextColumn({
      Header: "Resource Type",
      accessor: "resource_type",
      cellType: "alpha-numeric",
    }),
    hvTextColumn({
      Header: "Resources Category",
      accessor: "resources_category",
      cellType: "alpha-numeric",
    }),
    hvTextColumn({
      Header: "URL",
      accessor: "url",
      cellType: "alpha-numeric",
    }),
    hvTextColumn({
      Header: "User Email",
      accessor: "user",
      cellType: "alpha-numeric",
    }),
    hvTextColumn({
      Header: "User Group",
      accessor: "user_group",
      cellType: "alpha-numeric",
    }),
    hvDateColumn(
      {
        Header: "Created Date",
        accessor: "created_date",
      },
      "MM/DD/YY"
    ),
  ];

  const columns = useMemo(() => {
    return getColumns();
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headers,
    page,
    gotoPage,
    state: { pageSize, pageIndex, sortBy },
    getHvPaginationProps,
  } = useHvData(
    {
      columns,
      data,
      defaultColumn: {
        Cell: ({ value }) => value ?? "—",
      },
      manualPagination: true,
      manualSortBy: true,
      disableCreateExpandButton: true,
      pageCount: totalRecords,
    },
    useHvSortBy,
    useHvPagination
  );

  useEffect(() => {
    gotoPage(0);
  }, [sortBy, gotoPage]);

  useEffect(() => {
    let tableParams = {};
    tableParams["start_date"] = props?.startDate;
    tableParams["end_date"] = props?.endDate;
    tableParams["limit"] = pageSize;
    tableParams["offset"] = pageSize * pageIndex;
    reportsServiceObj
      .getResourceUtilization(buildQueryParams(tableParams))
      .then((response) => {
        const tableData = response?.results?.map((item) => {
          return {
            id: item.id,
            lab: item.lab,
            resource_name: item.resource_name,
            resource_type: item.resource_type,
            resources_category: item.resources_category,
            url: item.url,
            user: item.user,
            user_group: item.user_group.map((group) => group.name).join(", "),
            created_date: item.created_date,
          };
        });

        setData(tableData);
        setTotalRecords(Math.ceil(response?.count / pageSize));
      });
  }, [pageSize, pageIndex, props?.startDate, props?.endDate]);

  const EmptyRow = () => (
    <HvTableRow>
      <HvTableCell colSpan={100} style={{ height: 50 }}>
        <HvEmptyState
          message="No data to display"
          icon={<Ban role="presentation" />}
        />
      </HvTableCell>
    </HvTableRow>
  );

  const rowRenderer = (pages) => {
    return pages.map((row, index) => {
      prepareRow(row);

      return (
        <React.Fragment key={row.id}>
          <HvTableRow
            key={row.Header}
            {...row.getRowProps({
              "aria-rowindex": index,
            })}
          >
            {row.cells.map((cell) => (
              <HvTableCell key={cell.Header} {...cell.getCellProps()}>
                {cell.render("Cell")}
              </HvTableCell>
            ))}
          </HvTableRow>
        </React.Fragment>
      );
    });
  };

  return (
    <>
      {data.length > 0 ? (
        <>
          <HvTableContainer>
            <HvTable
              {...getTableProps({
                "aria-rowcount": data.length,
                caption: "Table Caption",
              })}
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              <HvTableHead>
                <HvTableRow>
                  {headers.map((col) => (
                    <HvTableHeader key={col.Header} {...col.getHeaderProps()}>
                      {col.render("Header")}
                    </HvTableHeader>
                  ))}
                </HvTableRow>
              </HvTableHead>
              <HvTableBody {...getTableBodyProps()}>
                {page.length === 0 ? <EmptyRow /> : rowRenderer(page)}
              </HvTableBody>
            </HvTable>
          </HvTableContainer>
          <HvPagination {...getHvPaginationProps()} />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

TableViewResourceUtilization.propTypes = {
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  // region: PropTypes.any,
};

export default TableViewResourceUtilization;
