import React, { memo, useEffect, useState } from 'react';
import { HvPanel, HvDropdown, HvTypography, HvDatePicker, HvGrid, HvSection, HvDropDownMenu, HvEmptyState } from '@hitachivantara/uikit-react-core';
import { HvBarChart, HvDonutChart } from '@hitachivantara/uikit-react-viz';
import ReportService from 'services/ReportService';
import moment from 'moment';
import PropTypes from 'prop-types';
import { buildQueryParams } from 'utils/common';
import TableViewDepartment from './TableViewDepartment';
import { Info } from '@hitachivantara/uikit-react-icons';


const Department = memo((props) => {
  const [departmentData, setDepartmentData] = useState();
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [barChartData, setBarChartData] = useState([]);
  const [selectedRadioButton, setSelectedRadioButton] = useState(0);
  const defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate() - 30);
  const [startDate, setStartDate] = useState(defaultDate);
  const [endDate, setEndDate] = useState(new Date());
  const labels = {
    applyLabel: "Apply",
    cancelLabel: "Cancel",
  };
  const reportServerObj = new ReportService();


  function roundToTwoDecimalsAndAdjust(percentages) {
    let rounded = percentages.map(num => Math.round(num * 100) / 100);
    let total = rounded.reduce((acc, val) => acc + val, 0);
    total = Math.round(total * 100) / 100;
    let diff = Math.round((100 - total) * 100) / 100;

    while (diff !== 0) {
      for (let i = 0; i < rounded.length; i++) {
        if (diff > 0 && rounded[i] < 100) {
          rounded[i] = Math.round((rounded[i] + 0.01) * 100) / 100;
          diff = Math.round((diff - 0.01) * 100) / 100;
        } else if (diff < 0 && rounded[i] > 0) {
          rounded[i] = Math.round((rounded[i] - 0.01) * 100) / 100;
          diff = Math.round((diff + 0.01) * 100) / 100;
        }
        if (diff === 0) break;
      }
    }

    return rounded;
  }

  const getPDFLabReport = () => {
    let tableParams = {};
    tableParams["start_date"] = moment(startDate).format("YYYY-MM-DD");
    tableParams["end_date"] = moment(endDate).format("YYYY-MM-DD");
    tableParams["file_format"] = "pdf";
    if (selectedDepartment !== "All") {
      tableParams["department"] = selectedDepartment
    }
    reportServerObj.getDepartmentDataDownload(buildQueryParams(tableParams))
      .then((response) => {
        // Create a URL for the Blob
        const url = window.URL.createObjectURL(response);

        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = "department_report.pdf"; // Set the desired filename

        // Append the link to the DOM and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the URL
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching PDF data:", error);
      });
  };

  const getExcelLabReport = () => {
    let tableParams = {};
    tableParams["start_date"] = moment(startDate).format("YYYY-MM-DD");
    tableParams["end_date"] = moment(endDate).format("YYYY-MM-DD");
    if (selectedDepartment !== "All") {
      tableParams["department"] = selectedDepartment
    }
    reportServerObj.getDepartmentDataDownload(buildQueryParams(tableParams))
      .then((response) => {
        // Create a URL for the Blob
        // const blob = response.blob();
        const url = window.URL.createObjectURL(response);

        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = url;
        link.download = "department_report.xlsx"; // Set the desired filename

        // Append the link to the DOM and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up by revoking the URL
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error fetching Excel data:", error);
      });
  };
  useEffect(() => {
    try {
      reportServerObj.getAllDepartment().then(response => {
        if (response?.results) {

          const filteredData = response?.results.map(item => ({

            label: item.department || "Unknown"

          }));

          setDepartmentData(filteredData)
        }

      })
    } catch (error) {
      console.log(error);
    }
  }, [])

  useEffect(() => {
    try {
      const paylaod = {
        department: selectedDepartment === "Unknown" ? null : selectedDepartment,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
      }
      reportServerObj.getDepartmentChartData(buildQueryParams(paylaod)).then(response => {
        if (response) {

          if (response?.total_session?.length > 0) {
            const barReportData = [{
              total_session: response?.total_session,
              department: response?.department,
              percentage: response?.percentage?.length !== 0 ? roundToTwoDecimalsAndAdjust(response?.percentage) : []
            }]
            setBarChartData(barReportData);
          } else {
            setBarChartData([]);
          }
        }
      })
    } catch (error) {
      console.log(error);
    }
  }, [selectedDepartment, startDate, endDate])

  return (
    <>
      <div style={{ paddingTop: "10px", display: "flex", flexDirection: "column", gap: "30px" }} >
        <HvPanel title="Lab Usage By Department"><HvTypography variant='xsTitle'>Lab Usage By Department</HvTypography></HvPanel>
      </div>
      <div style={{ paddingTop: "10px", display: "flex", flexDirection: "row", gap: "30px", alignContent: "center" }}>
        <HvDropdown label='Select Department' status='valid' values={departmentData} multiSelect showSearch onChange={(value) => {
          setSelectedDepartment(value?.map(item => item.label === "Unknown" ? 0 : item.label).join(', '))
        }} style={{ width: "20%" }} />
        <HvDatePicker
          id="DatePicker"
          aria-label="Date"
          placeholder="Select a range"
          labels={labels}
          rangeMode
          showClear
          calendarProps={{
            maximumDate: new Date(),
          }}
          startValue={startDate}
          endValue={endDate}
          onChange={(startDate, endDate) => {
            setStartDate(startDate);
            setEndDate(endDate);

          }}
          onClear={() => {
            setBarChartData([]);

          }}
          style={{
            alignSelf: 'center',
            paddingTop: '26px'
          }}
        />
        {barChartData?.length > 0 ? (
          <HvGrid
            item
            xs={1}
            sm={1}
            md={2}
            lg={1}
            xl={2}
            style={{ position: "absolute", top: 165, right: 100 }}
          >
            {selectedRadioButton === 1 ? (
              <>
                <HvDropDownMenu
                  dataList={[
                    {
                      label: "Download Excel Report",
                    },
                    {
                      label: "Download PDF Report",
                    },
                  ]}
                  //defaultExpanded
                  placement="left"
                  size="md"
                  variant="secondaryGhost"
                  onClick={(e, value) =>
                    value.label === "Download Excel Report"
                      ? getExcelLabReport()
                      : getPDFLabReport()
                  }
                />
              </>
            ) : (
              <>
                <HvDropDownMenu
                  dataList={[
                    {
                      label: "Download Excel Report",
                    },
                    {
                      label: "Download PDF Report",
                    },
                  ]}
                  //defaultExpanded
                  placement="left"
                  size="md"
                  variant="secondaryGhost"
                  onClick={(e, value) =>
                    value.label === "Download Excel Report"
                      ? getExcelLabReport()
                      : getPDFLabReport()
                  }
                />
              </>
            )}
          </HvGrid>
        ) : (
          ""
        )}
      </div>
      <HvGrid container style={{ paddingTop: "30px" }}>
        {barChartData?.length > 0 ? (
          <>
            <HvGrid
              item
              xs={4}
              sm={6}
              md={4}
              lg={6}
              xl={6}
              style={{ paddingTop: "60px" }}
            >
              <HvSection
                key={"bar-0"}
                title={
                  <HvTypography variant="label">
                    Lab Usage By Department
                  </HvTypography>
                }
              >
                <HvBarChart
                  key={"bar-0"}
                  data={{
                    Session: barChartData[0]["total_session"],
                    department: barChartData[0]["department"],
                  }}
                  groupBy={"department"}
                  measures={"Session"}
                  stack="total"
                  horizontal
                  title="Lab Usage By Department"
                  height={400}
                  yAxis={{
                    type: "categorical",
                    position: "left",
                    label: {
                      formatter: function (value) {
                        return value.split(" ").join("\n");
                      },
                    },
                  }}
                  grid={{ left: 100 }}
                  style={{
                    width: "100%",
                    overflow: "hidden",
                    whiteSpace: "normal",
                  }}

                  onOptionChange={(option) => {
                    if (
                      Array.isArray(option.yAxis) &&
                      option.yAxis.length === 1
                    ) {
                      option.yAxis = [
                        {
                          ...option.yAxis[0],
                          axisLabel: {
                            ...option.yAxis[0].axisLabel,
                            width: 90,
                            overflow: "truncate",

                          },
                        },
                      ];
                    }
                    option.dataZoom = barChartData[0]?.total_session?.length > 10 ? [
                      {
                        type: 'slider',
                        yAxisIndex: 0,
                        start: 0,
                        end: barChartData[0]?.total_session?.length > 10 ? 10 : 100, // Show the first 10 items initially
                        labelFormatter: '' // Set to empty to remove the labels
                      }
                    ] : []

                    option.graphic = barChartData[0]?.total_session.length === 0 ? {
                      type: 'text',
                      left: 'left',
                      top: 'middle',
                      style: {
                        text: 'No data',
                        fontSize: 30,
                        fill: '#aaa',
                      },
                    } : {}

                    option.tooltip = {
                      confine: true,

                    }
                    return option;
                  }}
                />
              </HvSection>
            </HvGrid>
          </>
        ) : (
          <></>
        )}
        {barChartData.length > 0 ? (
          <>
            <HvGrid
              item
              xs={4}
              sm={4}
              md={4}
              lg={6}
              xl={6}
              style={{ paddingTop: "60px" }}
            >
              <HvSection
                key={"donut-2"}
                classes={{
                  content: "css-10klw3m",
                }}
                title={
                  <HvTypography variant="label">
                    Lab Usage By Department (%)
                  </HvTypography>
                }
              >
                <HvDonutChart
                  key={"donut-2"}
                  data={{
                    percentage: barChartData[0]["percentage"],
                    department: barChartData[0]["department"],
                  }}
                  groupBy={"department"}
                  measures={"percentage"}
                  type="regular"
                  title="Lab Usage by Region"
                  height={400}
                  width={700}
                  measure={{
                    field: "percentage",
                  }}
                  legend={{
                    direction: "vertical",
                    position: {
                      x: "left",
                      y: "top",
                    },
                  }}
                  onOptionChange={(option) => {
                    option.series = [{
                      type: 'pie',
                      radius: ['50%', '70%'],
                      avoidLabelOverlap: false,
                      label: {
                        show: false,
                        position: 'center'
                      }
                    }]
                    option.legend = {
                      orient: 'vertical',
                      type: 'scroll',
                      left: 'left',  // Position the legend to the left
                      top: 'middle'  // Center the legend vertically
                    }
                    option.tooltip = {
                      confine: true,
                      formatter: '{b} : {d}%'
                    }
                    return option;
                  }}
                />
              </HvSection>
            </HvGrid>
          </>
        ) : (
          <></>
        )}
      </HvGrid>
      <HvGrid container style={{ paddingTop: "50px" }} columnGap={10}>
        {barChartData?.length > 0 ? (
          <>
            <HvGrid item xs={4} sm={8} md={8} lg={12} xl={12}>
              <TableViewDepartment startDate={moment(startDate).format("YYYY-MM-DD")}
                endDate={moment(endDate).format("YYYY-MM-DD")} department={selectedDepartment} />
            </HvGrid>
          </>
        ) : (
          <></>
        )}

        {
          barChartData.length === 0 ?
            <HvEmptyState
              icon={<Info />}
              message="No data to display."
            /> : <></>
        }
      </HvGrid>
    </>

  );
});

Department.propTypes = {

};

export default Department;