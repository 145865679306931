import React, { useState, useMemo, useEffect } from "react";
import {
  HvTableContainer,
  HvTable,
  HvTableHead,
  HvTableRow,
  HvTableHeader,
  HvTableBody,
  HvTableCell,
  HvPagination,
  useHvPagination,
  useHvData,
  HvEmptyState,
  hvTextColumn,
  useHvSortBy,
  hvNumberColumn,
} from "@hitachivantara/uikit-react-core";
import { Ban } from "@hitachivantara/uikit-react-icons";
import _ from "lodash";
import PropTypes from "prop-types";
import ReportService from "services/ReportService.js";
import { buildQueryParams } from "utils/common.js";

const TableViewDepartment = (props) => {
  const [data, setData] = useState([]);
  const [totalRecords, setTotalRecords] = useState();
  const [startDate] = useState(props?.startDate);
  const [endDate] = useState(props.endDate);
  const reportsServiceObj = new ReportService();
  
  const getColumns = () => [
    hvTextColumn({
      Header: "Lab Name",
      accessor: "lab__name",
      cellType: "alpha-numeric",
    }),
    
    hvTextColumn({
      Header: "Department",
      accessor: "user__department",
      cellType: "alpha-numeric",
    }),
    
    hvNumberColumn({
      Header: "Total Session",
      accessor: "total_sessions",
      cellType: "numeric",
    }),
  ];
  const columns = useMemo(() => {
    return getColumns();
  }, []);

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headers,
    page,
    gotoPage,
    state: { pageSize, pageIndex, sortBy },
    getHvPaginationProps,
  } = useHvData(
    {
      columns,
      data,
      defaultColumn: {
        Cell: ({ value }) => value ?? "—",
      },
      manualPagination: true,
      manualSortBy: true,
      disableCreateExpandButton: true,
      pageCount: totalRecords,
    },
    useHvSortBy,
    useHvPagination
  );

  useEffect(() => {
    gotoPage(0);
  }, [sortBy, gotoPage]);

  useEffect(() => {
    let tableParams = {};
    tableParams["start_date"] = props?.startDate;
    tableParams["end_date"] = props?.endDate;
    tableParams["limit"] = pageSize;
    tableParams["offset"] = pageSize * ((pageIndex + 1) - 1);
    if(props?.department !== "All"){
        tableParams["department"] = props?.department
    }
    reportsServiceObj
      .getDepartmentTableData(buildQueryParams(tableParams))
      .then((response) => {
        const tableData = response.results?.map((item) => {
          let output = {};
          if (!_.isNull(item)) {
            output.user__department = item?.user__department;
            output.lab__name = item.lab__name;
            output.total_sessions = item.total_sessions;
            return output;
          }
        });
        setData(tableData);
        setTotalRecords(Math.ceil(response?.count / pageSize));
      });

  }, [pageSize, pageIndex, props?.startDate, props?.endDate, props?.department]);

  const EmptyRow = () => (
    <HvTableRow>
      <HvTableCell colSpan={100} style={{ height: 50 }}>
        <HvEmptyState
          message="No data to display"
          icon={<Ban role="presentation" />}
        />
      </HvTableCell>
    </HvTableRow>
  );

  const rowRenderer = (pages) => {
    return pages.map((row, index) => {
      prepareRow(row);

      return (
        <React.Fragment key={row.id}>
          <HvTableRow
            key={row.Header}
            {...row.getRowProps({
              "aria-rowindex": index,
            })}
          >
            {row.cells.map((cell) => (
              <HvTableCell key={cell.Header} {...cell.getCellProps()}>
                {cell.render("Cell")}
              </HvTableCell>
            ))}
          </HvTableRow>
        </React.Fragment>
      );
    });
  };


  return (
    <>
      {
        data.length > 0 ? <>
          <HvTableContainer>
            <HvTable
              {...getTableProps({
                "aria-rowcount": data.length,
                caption: "Table Caption",
              })}
              style={{
                width: "100%",
                position: "relative",
              }}
            >
              <HvTableHead>
                <HvTableRow>
                  {headers.map((col) => (
                    <HvTableHeader key={col.Header} {...col.getHeaderProps()}>
                      {col.render("Header")}
                    </HvTableHeader>
                  ))}
                </HvTableRow>
              </HvTableHead>
              <HvTableBody {...getTableBodyProps()}>
                {page.length === 0 ? <EmptyRow /> : rowRenderer(page)}
              </HvTableBody>
            </HvTable>
          </HvTableContainer>
          <HvPagination {...getHvPaginationProps()} />
        </>
          : <></>
      }

    </>
  );
};

TableViewDepartment.propTypes = {
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  department: PropTypes.any
};

export default TableViewDepartment;
