import React, { useState, useMemo, useEffect } from "react";
import { Info, Delete, Ban, Canceled } from "@hitachivantara/uikit-react-icons";
import LabService from 'services/LabService';
import { showLoader } from 'redux/actions/commonActions';
import { showBanner } from 'redux/actions/bannerActions';
import { useDispatch } from 'react-redux';
import {
    HvTableContainer,
    HvTable,
    HvTableHead,
    HvTableRow,
    HvTableHeader,
    HvTableBody,
    HvTableCell,
    HvPagination,
    useHvPagination,
    useHvData,
    HvEmptyState,
    hvTextColumn,
    useHvSortBy,
    hvNumberColumn,
    hvDateColumn,
    HvTooltip,
    HvButton,
    HvDialog, HvDialogTitle, HvDialogContent, HvDialogActions
} from "@hitachivantara/uikit-react-core";
import _ from "lodash";
import PropTypes from "prop-types";
import ReportService from "services/ReportService.js";
import { buildQueryParams } from "utils/common.js";

const HardwareTableView = (props) => {
    const labServiceObj = new LabService();
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState();
    const [startDate] = useState(props?.startDate);
    const [endDate] = useState(props.endDate);
    const [currentScheduleId, setCurrentScheduleId] = useState('');
    const [confirmCancel, setConfirmCancel] = useState(false);
    const reportsServiceObj = new ReportService();


    const getColumns = () => [
        hvTextColumn({
            Header: "Lab Name",
            accessor: "lab_name",
            cellType: "alpha-numeric",
        }),

        hvDateColumn(
            { Header: "Schedule From", accessor: "schedule_from", style: { minWidth: 80 } },
            "MM/DD/YYYY:h:mm a"
        ),
        hvDateColumn(
            { Header: "Schedule To", accessor: "schedule_to", style: { minWidth: 80 } },
            "MM/DD/YYYY:h:mm a"
        ),

        hvTextColumn({
            Header: "User",
            accessor: "user",
            cellType: "alpha-numeric",
        }),
        hvTextColumn({
            Header: "User Type",
            accessor: "user_type",
            cellType: "alpha-numeric",
        }),
        hvTextColumn({
            Header: "Category",
            accessor: "category",
            cellType: "alpha-numeric",
        }),
        {
            Header: "Action",
            accessor: "id",
            Cell: ({ row }) => (

                <> {
                    new Date(row?.original?.schedule_from) > new Date() &&
                    < div style={({ display: "flex", cursor: "pointer" })}>
                        <HvTooltip title="Cancel Booking"><HvButton variant='primaryGhost' onClick={() => {
                            setConfirmCancel(!confirmCancel);
                            setCurrentScheduleId(row?.original?.id)
                        }}><Delete /></HvButton></HvTooltip>
                    </div>
                }
                </>

            ),
            style: { textAlign: "center" }, // Optional: Center the button in the column
        },

    ];
    const columns = useMemo(() => {
        return getColumns();
    }, []);

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headers,
        page,
        gotoPage,
        state: { pageSize, pageIndex, sortBy },
        getHvPaginationProps,
    } = useHvData(
        {
            columns,
            data,
            defaultColumn: {
                Cell: ({ value }) => value ?? "—",
            },
            manualPagination: true,
            manualSortBy: true,
            disableCreateExpandButton: true,
            pageCount: totalRecords,
        },
        useHvSortBy,
        useHvPagination
    );

    useEffect(() => {
        gotoPage(0);
    }, [sortBy, gotoPage]);

    const handleActionClick = (id) => {
        console.log("Unit ID clicked:", id);
        // Add logic for button action, e.g., navigate, open modal, or call API
    };
    const getAllBookings = () => {
        let tableParams = {};
        if (startDate && endDate) {
            tableParams["schedule_from"] = props?.startDate;
            tableParams["schedule_to"] = props?.endDate;
        }

        tableParams["limit"] = pageSize;
        tableParams["offset"] = pageSize * ((pageIndex + 1) - 1);
        tableParams["categories"] = props?.categories
        tableParams["user_type"] = props?.userType
        reportsServiceObj
            .getAllHardwareBoockings(buildQueryParams(tableParams))
            .then((response) => {
                const tableData = response.results?.map((item) => {
                    let output = {};
                    if (!_.isNull(item)) {
                        output.id = item?.id;
                        output.lab_name = item?.lab_info?.name;
                        output.user = item?.user_name;
                        output.schedule_from = item.schedule_from;
                        output.schedule_to = item.schedule_to;
                        output.category = item?.lab_info?.categories?.join(",");
                        output.user_type = item?.user_type?.join(",");
                        return output;
                    }
                });
                setData(tableData);
                setTotalRecords(Math.ceil(response?.count / pageSize));
            });

    }
    useEffect(() => {
        getAllBookings()

    }, [pageSize, pageIndex, props?.startDate, props?.endDate, props?.categories, props?.userType]);

    const EmptyRow = () => (
        <HvTableRow>
            <HvTableCell colSpan={100} style={{ height: 50 }}>
                <HvEmptyState
                    message="No data to display"
                    icon={<Ban role="presentation" />}
                />
            </HvTableCell>
        </HvTableRow>
    );

    const rowRenderer = (pages) => {
        return pages.map((row, index) => {
            prepareRow(row);

            return (
                <React.Fragment key={row.id}>
                    <HvTableRow
                        key={row.Header}
                        {...row.getRowProps({
                            "aria-rowindex": index,
                        })}
                    >
                        {row.cells.map((cell) => (
                            <HvTableCell key={cell.Header} {...cell.getCellProps()}>
                                {cell.render("Cell")}
                            </HvTableCell>
                        ))}
                    </HvTableRow>
                </React.Fragment>
            );
        });
    };

    const cancelSchedule = (scheduleId) => {
        if (scheduleId) {
            setConfirmCancel(!confirmCancel);
            dispatch(showLoader(true));
            labServiceObj.cancelSelectedSlot(scheduleId).then(response => {
                dispatch(showLoader(false));
                dispatch(showBanner({
                    payload: {
                        showBanner: true,
                        label: { message: "Booking Cancelled." },
                        variant: "success"
                    }

                }))
                getAllBookings();
            }).catch(error => {
                console.log(error);
            })
        }

    }

    return (
        <>
            {
                confirmCancel ? <>
                    <HvDialog
                        disableBackdropClick
                        open={confirmCancel}
                        onClose={() => setConfirmCancel(false)}
                        id="delete"
                    >
                        <HvDialogTitle variant="warning">Cancel Booking ?</HvDialogTitle>
                        <HvDialogContent indentContent>
                            Do you really want to Cancel
                        </HvDialogContent>
                        <HvDialogActions>
                            <HvButton id="apply" onClick={() => { cancelSchedule(currentScheduleId) }} variant="primaryGhost">
                                Yes
                            </HvButton>
                            <HvButton id="cancel" variant="primaryGhost" onClick={() => setConfirmCancel(false)}>
                                No
                            </HvButton>
                        </HvDialogActions>
                    </HvDialog>
                </> : <></>
            }
            {
                data.length > 0 ? <>
                    <HvTableContainer>
                        <HvTable
                            {...getTableProps({
                                "aria-rowcount": data.length,
                                caption: "Table Caption",
                            })}
                            style={{
                                width: "100%",
                                position: "relative",
                            }}
                        >
                            <HvTableHead>
                                <HvTableRow>
                                    {headers.map((col) => (
                                        <HvTableHeader key={col.Header} {...col.getHeaderProps()}>
                                            {col.render("Header")}
                                        </HvTableHeader>
                                    ))}
                                </HvTableRow>
                            </HvTableHead>
                            <HvTableBody {...getTableBodyProps()}>
                                {page.length === 0 ? <EmptyRow /> : rowRenderer(page)}
                            </HvTableBody>
                        </HvTable>
                    </HvTableContainer>
                    <HvPagination {...getHvPaginationProps()} />
                </>
                    : <HvEmptyState
                        icon={<Info />}
                        message="No data to display."
                    />
            }

        </>
    );
};

HardwareTableView.propTypes = {
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    categories: PropTypes.any,
    userType: PropTypes.any
};

export default HardwareTableView;
